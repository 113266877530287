@charset 'UTF-8';

// Breadlist
// ---------
.breadlist-wrapper {
  background: $background-breadlist;
  margin: 1em 0 2em;
  padding: 8px;
  @include media(lg) {
    margin: 2em 0;
    padding: 12px 20px;
  }
  @include widthWrapper( $breadlist-container-width, $breadlist-container-padding );
}

.breadlist-container {
  margin: 0 auto;
  @include widthContainer( $breadlist-container-width );
}

.breadlist {
  display: flex;
  align-items: center;
  font-size: .725em;

  li {
    color: inherit;
    line-height: 1.4;
    text-overflow: ellipsis;
    white-space: nowrap;

    & + li::before {
      content: '＞';
      color: $gray-700;
      padding-right: .5em;
      padding-left: .5em;
    }

    a {
      color: $body-text-color;
      transition: color $transition;
      &:hover {
        color: lighten( $body-text-color, 20% );
      }
    }

    &.current {
      color: $gray-800;
    }
  }
}
