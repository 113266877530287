@charset 'UTF-8';

// icon color
//
.icon-blue { color: #ACDAE5; }
.icon-blue-02 { color: #83C2CD; }
.icon-green { color: #CDDD9E; }
.icon-pink { color: #F2BBBB; }
.icon-violet { color: #D0CAE5; }



// nav
//
.sm-nav {
  background: #287791;
  display: block;
  opacity: 0;
  overflow: auto;
  padding: 55px 16px 20px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
  transition: opacity $transition, visibility $transition;
  // pc
  @include media(lg) {
    display: none;
  }
  // burger クリック時
  .open-menu & {
    opacity: 1;
    visibility: visible;
  }
}
// pc
.global-nav-wrapper {
  display: none;
  @include media(lg) {
    display: block;
    z-index: 9999;
  }
}



// メニュー表示されたとき
body.open-menu {
  overflow: hidden;
}



// sm メニューを閉じるボタン
.global-nav-close-button {
  color: $gray-200;
  cursor: pointer;
  display: block;
  font-size: 3.2rem;
  position: fixed;
  top: 8px;
  right: 12px;
  z-index: 4;
  @include media(lg){
    display: none;
  }
}


// navigation @used mixin
//
@mixin fontAwesomeNav( $content: '\f078', $weight: 700)
{
  content: $content;
  font-family: 'Font Awesome 5 Pro';
  font-weight: $weight;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

// navigation
//
.global-nav-list {
  overflow: auto;
  > li {
    margin-bottom: 2px;
    .trigger {
      background: rgba( #439baa, .4 );
      color: $white-color;
      cursor: pointer;
      display: block;
      font-size: 1.4rem;
      padding: 20px 24px 20px 16px;
      position: relative;
      &:after {
        content: '\f078';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 700;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
      }
    }
    .global-nav-inner-container {
      display: none;
    }
    .nav-category {
      background: rgba(67, 155, 170, .2);
      color: $white-color;
      cursor: pointer;
      display: block;
      padding: 16px 24px 16px 28px;
      position: relative;
      &:after {
        @include fontAwesomeNav();
      }
      i {
        margin-right: 8px;
      }
    }
    .child-nav-list {
      display: none;
       > li {
        a {
          background: darken( #287791, 10% );
          color: $white-color;
          display: block;
          padding: 12px 24px 12px 36px;
          position: relative;
          &:after {
            @include fontAwesomeNav( '\f061' );
          }
        }
      }
    }
  }
  // pc
  @include media(lg){
    display: flex;
    flex-flow: row wrap;
    overflow: inherit;
    position: relative;
    > li {
      flex: 1 0 auto;
      margin: 0;
      transition: background $transition;
      &:hover {
        background: #F2F7F7;
      }
      .trigger {
        background: transparent;
        color: #439BAA;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        font-weight: bold;
        height: 60px;
        padding: 0;
        position: relative;
        text-align: center;

        &:after {
          content: none;
        }
        &:before {
          background: #ddd;
          content: '';
          margin-top: -15px;
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 30px;
        }
      }
      &:last-child .trigger {
        &:after {
          background: #ddd;
          content: '';
          margin-top: -15px;
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 30px;
          transform: none;
        }
      }
      .global-nav-inner-container {
        background: #F2F7F7;
        box-shadow: 0 3px 3px rgba(0,0,0,.16);
        display: flex;
        opacity: 0;
        visibility: hidden;
        padding: 40px 20px 40px 40px;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        transition: visibility $transition, opacity $transition;
        &.is-active {
          opacity: 1;
          visibility: visible;
        }

        .nav-col-6 {
          flex: 0 0 50%;
        }
      }
      .nav-category {
        background: transparent;
        color: $body-text-color;
        cursor: initial;
        display: inline-flex;
        font-size: 2rem;
        padding: 0;
        position: relative;
        &:after {
          content: none;
        }
        i {
          display: inline-block;
          margin: 0px 18px 0 0;
          font-size: 3.2rem;
        }
      }
      .child-nav-list {
        display: flex;
        flex-flow: row wrap;
        padding-top: 16px;
         > li {
          flex: 0 0 50%;
          margin-top: 8px;
          a {
            background: transparent;
            color: $body-text-color;
            display: inline-block;
            font-size: 1.4rem;
            padding: 0 0 0 22px;
            position: relative;
            &:after {
              content: none;
            }
            &:before {
              content: '\f138';
              color: #55A4B2;
              font-family: 'Font Awesome 5 Pro';
              font-weight: 900;
              line-height: 1;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-46%);
            }
          }
        }
      }
    }
  }
}

// hover で子メニューを表示
@include media(lg) {
  .global-nav-list > li:hover .global-nav-inner-container {
    visibility: visible;
    opacity: 1;
  }
}
