@charset 'UTF-8';

// Copyright
// ---------
.copyright-wrapper {
  background: $background-copyright;
  flex: none;
  padding: 30px map-unit( $copyright-container-padding, sm );
  position: relative;
  width: 100%;
  z-index: map-unit( $z-index, copyright );
  @include media(lg) {
    padding: 40px map-unit( $copyright-container-padding, lg ) 30px;
  }
  @include widthWrapper( $copyright-container-width, $copyright-container-padding );
}

.copyright-container {
  margin: 0 auto;
  @include widthContainer( $copyright-container-width );
}

.copyright {
  color: $body-text-color;
  font-size: 75%;
  text-align: center;
}

