@charset 'UTF-8';

// header
// ------

header {
  background: transparent;
  padding-bottom: map-unit( $header-padding-bottom, sm );
  width: 100%;
  z-index: map-unit($z-index, header);
  @include media(lg) {
    padding-bottom: map-unit( $header-padding-bottom, lg );
  }
}

.header-wrap {
  background: $background-header;
  position: relative;
  padding: 0 map-unit( $header-container-padding, sm );
  @include media(lg) {
    padding: 0 map-unit( $header-container-padding, lg );
  }
  @include widthWrapper( $header-container-width, $header-container-padding );
}

.header-container {
  margin: 0 auto;
  @include widthContainer( $header-container-width );
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: map-unit( $height-header, sm, rem );
  @include media(lg) {
    height: map-unit( $height-header, lg, rem );
  }
}

.header-title {
  img {
    width: auto;
    height: 30px;
    @include media(lg){
      width: auto;
      height: 60px;
    }
  }
}
