@charset 'UTF-8';

// monosus からの移植

// header 右部の電話番号周り
//
.header-contact {
  display: none;
  @include media(lg){
    display: block;
    line-height: 1;
    text-align: right;
  }
  p {
    color: #999;
    font-size: 1.2rem;
  }
  .btn-tel {
    display: inline-block;
    font-size: 2.4rem;
    i {
      color: $base-color;
      font-size: 2.8rem;
      margin-right: 10px;
    }
    .tel-number {
      font-size: 2.8rem;
    }
  }
}



// top hero slider
//
.hero-top {
  position: relative;

  .text {
    position: absolute;
    top: 30px;
    left: 15px;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: .15rem;
    line-height: 1.5;

    @include media(lg) {
      top: 105px;
      left: 40px;
      font-size: 2.4rem;
    }
  }

  .list-hero-content {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 12px;
    width: 100%;
    padding-left: 7px;
    padding-right: 7px;

    @include media(lg){
      padding-left: 10px;
      padding-right: 10px;
      bottom: 20px;
    }

    > .item {
      width: 50%;
      padding-left: 4px;
      padding-right: 4px;

      @include media(lg){
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .box-hero {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 6px;
    @include media(lg){
      padding: 10px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $white-color;
      opacity: .7;
    }

    &-in {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      border: 1px solid rgba( 67, 155, 170, 0.3 );
      padding: 17px 10px 5px;
      z-index: 1;
      @include media(lg){
        padding: 20px 10px 8px;
      }
    }

    .detail {
      width: 100%;
    }

    .icon {
      color: $base-color;
      font-size: 3.2rem;
      @include media(lg){
        font-size: 6rem;
      }
    }

    .title {
      font-size: 1.6rem;
      margin-top: 15px;
      letter-spacing: 0;
      line-height: 1.3;
      @include media(lg){
        font-size: 3.2rem;
      }
    }

    .sub-title {
      font-size: .6rem;
      color: $gray-700;
      @include media(lg){
        font-size: 1.5rem;
        margin-top: 2px;
      }
    }
  }

  .slick-prev,
  .slick-next {
    width: 30px;
    height: 40px;
    z-index: 1;
    @include media(lg){
      width: 40px;
      height: 40px;
    }
  }
  .slick-prev {
    left: 15px;
    @include media(lg){
      left: -50px;
    }
  }
  .slick-next {
    right: 15px;
    @include media(lg){
      right: -50px;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: 'Font Awesome 5 Pro';
    font-size: 3rem;
    font-weight: 700;
    color: #d8d8d8;
    @include media(lg){
      font-size: 4rem;
    }
  }
  .slick-next:before {
    content: '\f138';
  }
  .slick-prev:before {
    content: '\f137';
  }
}

.box-map {
  iframe {
    display: block;
    width: 100%;
    height: 360px;
    @include media(lg){
      height: 300px;
    }
  }
}



// トップページの五つのボタン
//
.c-list-service {
  display: flex;
  flex-flow: row wrap;
  margin: 12px -4px 4px;
  @include media(lg){
    margin: 20px -4px 10px;
  }

  li {
    flex: 0 0 auto;
    width: percentage( 1/3 );
    padding: 4px;
    &:nth-child(1),
    &:nth-child(2) {
      flex: 0 0 auto;
      width: percentage( 1/2 );
    }
    @include media(lg){
      &, &:nth-child(1), &:nth-child(2){
        flex: 0 0 auto;
        width: percentage( 1/5 );
      }
    }
  }
}

.c-card-service {
  background: $wood-color;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 16px 10px 12px;
  text-align: center;
  @include media(lg){
    padding: 30px 10px 20px;
  }

  i {
    font-size: 3.2rem;
    margin-bottom: 12px;
    @include media(lg){
      font-size: 6.2rem;
      margin-bottom: 30px;
    }
    &.fa-edit {
      color: #f2bbbb;
    }
    &.fa-chalkboard-teacher {
      color: #d0cae5;
    }
    &.fa-heartbeat {
      color: #acdae5;
    }
    &.fa-bed {
      color: #cddd9e;
    }
    &.fa-graduation-cap {
      color: #f7dfa4;
    }
  }

  p {
    font-size: .8rem;
    @include media(lg){
      font-size: 1.6rem;
    }
  }
}



// トップページの三つのカテゴリ
//
.c-card-link {
  display: block;
  position: relative;

  .image {
    img {
      width: 100%;
    }
  }

  .caption {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    &-in {
      display: block;
      position: relative;
      text-align: center;
      padding: 8px 23px 7px 5px;
      background: rgba(67, 155, 170, 0.9);
      @include media(lg){
        padding: 11px 10px 9px;
      }

      &:before {
        content: '\f138';
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        font-family: 'Font Awesome 5 Pro';
        font-size: 0.9rem;
        font-weight: 700;
        color: $white-color;
      }

      &.md {
        padding: 6px 23px 5px 5px;

        &:before {
          right: 7px;
        }

        .text {
          font-size: 1.2rem;
        }
      }
    }

    .text {
      font-size: 1.6rem;
      color: #fff;
    }
  }
}



// タブ
//
.c-tab-heading {
  display: flex;
  position: relative;
  margin-left: -4px;
  margin-right: -4px;
  @include media(lg){
    margin-left: -10px;
    margin-right: -10px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 4px;
    bottom: 0;
    width: calc( 100% - 8px );
    height: 1px;
    background: $base-color;
    z-index: 1;
    @include media(lg){
      width: calc( 100% - 20px );
      height: 2px;
      left: 10px;
    }
  }

  > .item {
    width: 25%;
    padding: 0 4px;
    @include media(lg){
      padding: 0 10px;
    }
  }

  .title {
    display: block;
    font-size: 1.2rem;
    text-align: center;
    color: $base-color;
    background: #F9F6F2;
    padding: 11px 10px 10px;
    transition: color .3s, background .3s;
    cursor: pointer;
    @include media(lg){
      font-size: 1.6rem;
      padding: 17px 10px 13px;
    }

    &.is-active,
    &:hover {
      color: $white-color;
      background: $base-color;
      opacity: 1;
    }
  }
}
.c-tab-content {
  padding: 0 0 10px;
  @include media(lg){
    padding: 17px 30px 0;
  }
}


// タブの中のリスト
//
.c-list-news {
  > .item {
    border-bottom: 1px dashed #ccc;

    .inner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 15px 7px;
      @include media(lg){
        padding: 15px 0 13px;
      }
    }

    .box-date {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 5px;
      @include media(lg) {
        width: 250px;
        margin-bottom: 0;
      }

      .date {
        color: $base-color;
        font-size: 1rem;
        width: 80px;
        @include media(lg){
          font-size: 2rem;
          width: 135px;
        }
      }
    }

    .box-title {
      position: relative;
      width: 100%;
      padding-left: 0;
      padding-right: 16px;
      @include media(lg){
        flex: 1;
        padding-left: 20px;
        padding-right: 30px;
      }

      &:before {
        content: '\f054';
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        font-family: 'Font Awesome 5 Pro';
        font-size: 1rem;
        font-weight: 700;
        color: $base-color;
        @include media(lg){
          right: 12px;
          font-size: 1.3rem;
        }
      }

      .title {
        font-size: 1.2rem;
        &.pdf {
          &:after {
            content: '\f1c1';
            font-family: 'Font Awesome 5 Pro';
            font-size: 1.3rem;
            font-weight: 500;
            color: #C62B2B;
            margin-left: 15px;
            @include media(lg){
              font-size: 1.6rem;
              margin-left: 22px;
            }
          }
        }
        @include media(lg) {
          font-size: 1.6rem;
        }
      }
    }
  }
}



// common
//

.only-sp {
  display: block !important;
  @include media(lg){
    display: none !important;
  }
}

.only-pc {
  display: none !important;
  @include media(lg){
    display: block !important;
  }
}

.c-tag-01 {
  width: 52px;
  font-size: .8rem;
  text-align: center;
  background: $blue-lighter-color;
  padding: 1px 5px;
  @include media(lg){
    width: 115px;
    font-size: 1.4rem;
    padding: 3px 5px;
  }

  &.color-sand {
    background: $sand-color;
  }
  &.color-lilac {
    background: $lilac-color;
  }
}
