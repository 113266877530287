@charset 'UTF-8';

.pagetop-wrapper {
  cursor: pointer;
  margin: 32px 0 16px;
  text-align: right;
  @include media(lg){
    margin-top: 60px 0 32px;
  }
}

.pagetop {
  font-size: 1.2rem;
  transition: opacity $transition;
  @include media(lg){
    font-size: 1.6rem;
  }

  i {
    color: $first-color;
    margin-right: 12px;
    @include media(lg){
      margin-right: 20px;
    }
  }

  &:hover {
    opacity: .7;
  }
}
