@charset 'UTF-8';

// Breadlist Variables
// --------------------

// .breadlist の横幅 .container と合わせたい場合は $container-width を使用する
$breadlist-container-width: (
  'sm': map-get( $container-width, sm ),
  'lg': map-get( $container-width, lg )
) !default;

// .breadlist の左右の padding .container と合わせたい場合は $container-padding を使用する
$breadlist-container-padding: (
  'sm': 0,
  'lg': 0
) !default;

$background-breadlist: #F2F7F7 !default; // breadlist の background-color
