@charset 'UTF-8';

// border color
//
$burger-border-color: #999;

// bar の太さ
//
$burger-border-thickness: 4px;

// bar の長さ
//
$burger-border-width: (
  'sm': 21px,
  'lg': 21px
);

// bar 同士の間隔
//
$burger-border-margin: (
  'sm': 4px,
  'lg': 4px
);



// burger open 時に付与するクラス名
$burger-open-class: '.open-menu';
