@charset 'UTF-8';

// Table
// -----

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  //table-layout: fixed;

  th, td {
    border: 1px solid $table-border-color;
    padding: .5em 1em;
  }

  th {
    background: $table-th-bg-color;
    color: $base-color;
  }
  td {
    background: $table-td-bg-color;
    overflow-wrap : break-word;
  }
}

// th block
.th-block {
  border-bottom: 1px solid $table-border-color;
  @include media(lg) {
    border: none;
  }
  th, td {
    display: block;
    @include media(lg) {
      display: table-cell;
    }
  }
  th {
    width: 100%;
    @include media(lg) {
      width: 15%;
    }
  }
  td {
    border-top: none;
    border-bottom: none;

    @include media(lg) {
      border-top: 1px solid $table-border-color;
      border-bottom: 1px solid $table-border-color;
    }
  }
}

// スクロールを出すためのwrapper
// -----------------------
.table-scroll-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include media(lg) {
    white-space: normal;
  }
}
