@charset 'UTF-8';

// サイドナビゲーション
//
.side-navi-list {
  li {
    a {
      background: rgba(249, 246, 242, 0.5);
      border-bottom: 1px dashed #ccc;
      display: block;
      font-size: 1.2rem;
      padding: 13px 20px 11px;
      position: relative;
      transition: background $transition, color $transition;
      @include media(lg){
        font-size: 1.6rem;
        padding: 21px 20px 20px;
      }
      &:hover {
        color: $gray-500;
      }
      &:after {
        content: '\f138';
        color: $base-color;
        font-family: 'Font Awesome 5 Pro';
        font-size: 1em;
        font-weight: 700;
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY( -50% );
        @include media(lg){
          //font-size: 1rem;
          right: 1em;
        }
      }
      &.side-navi-list-heading {
        background: rgba( 67, 155, 170, 0.9 );
        border-bottom: none;
        color: $white-color;
        font-weight: bold;
        &:hover {
          background: $base-color;
        }
        &:after {
          color: $white-color;
        }
      }
    }
  }
}

// サイドナビゲーション（バナー
//
.side-banner-list {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -4px;
  li {
    flex: 0 0 50%;
    padding: 4px;
    width: 50%;
  }
  @include media(lg){
    display: block;
    margin: 0;
    li {
      padding: 0;
      width: 100%;
      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
  }
}

.side-navi-list + .side-banner-list {
  margin: 20px 0;
}



// コンテンツが一回り中に入り込むやつ
//
.contents-inner {
  margin: 0 auto;
  padding: 0 1em;
  @include media(lg){
    padding: 0 2em;
  }
  & + & {
    margin-top: map-unit( $section-gutter, sm ) * 2;
    @include media(lg){
      margin-top: map-unit( $section-gutter, lg ) * 2;
    }
  }
}



// 診療科などへのボタン（アイコンが下に来るやつ
//
.category-button {
  background: #F9F6F2;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  padding: 1.6em 2.4em 1.6em 1em;
  position: relative;
  transition: opacity $transition;
  &:hover {
    opacity: .7;
  }
  &:after {
    content: '\f138';
    color: #4AABB7;
    display: block;
    font-family: 'Font Awesome 5 Pro';
    font-size: 1em;
    font-weight: 700;
    margin-top: 1px;
    position: absolute;
    top: 50%;
    right: .8em;
    transform: translateY(-50%);
  }
  span {
    display: block;
  }
  @include media(lg){
    font-size: 1.2em;
    padding: 1.2em 1em 3em;
    text-align: center;
    &:after {
      font-size: 1.1em;
      top: auto;
      bottom: .6em;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.expanded {
    display: block;
    margin: 0;
    width: 100%;
  }
}



// ページ内リンク
//
.page-anchor {
  color: $base-color;
  display: inline-block;
  font-size: 1.2em;
  transition: color $transition;
  &:before {
    content: '\f078';
    display: inline-block;
    font-family: $icon-font-family;
    font-weight: 900;
    margin-right: .4em;
    width: 1.28571429em;
    text-align: center;
  }
  &:hover {
    color: lighten( $base-color, 10% );
  }
  @include media(lg){
    font-size: 1em;
  }
}
