@charset 'UTF-8';

// footer
// ------

footer {
  background: $background-footer;
  flex: none;
  padding-top: map-unit( $footer-padding-top, sm );
  width: 100%;
  z-index: map-unit( $z-index, footer );
  @include media(lg) {
    padding-top: map-unit( $footer-padding-top, lg );
  }
}

.footer-wrap {
  background: transparent;
  position: relative;
  padding: 0 map-unit( $footer-container-padding, sm );
  @include media(lg) {
    padding: 0 map-unit( $footer-container-padding, lg );
  }
  @include widthWrapper( $footer-container-width, $footer-container-padding );
}

.footer-container {
  margin: 0 auto;
  @include widthContainer( $footer-container-width );
}



// footer banner
//
.footer-banner {
  display: flex;
  flex-flow: row wrap;
  margin: 30px -4px 0;
  overflow: hidden;
  @include media(lg){
    margin: 48px -10px 0;
  }
  li {
    flex: 1 0 auto;
    width: 50%;
    padding: 0 4px;
    &:nth-child(n+3){
      margin-top: 8px;
    }
    @include media(lg){
      flex: 1 0 auto;
      width: 25%;
      padding: 0 10px;
      &:nth-child(n+3){
        margin-top: 0;
      }
    }
  }
}



// footer-contact
//
.footer-contact {
  font-size: 1.2rem;
  line-height: 1.66;
  text-align: center;
  @include media(lg){
    font-size: 1.4rem;
    line-height: 1.6;
    text-align: left;
  }
  .hospital-name {
    font-size: 1.8rem;
    margin-bottom: 8px;
    @include media(lg){
      font-size: 2.4rem;
      line-height: 1.4;
      margin-bottom: 16px;
    }
  }
  .hospital-tel {
    color: $body-text-color;
    display: inline-block;
    i {
      color: $base-color;
      font-size: 1.4rem;
      margin-right: 6px;
    }
  }
}



// footer-links
//
.footer-links {
  margin-top: 30px;
  @include media(lg){
    margin-top: 0;
  }
  li {
    &:not(:first-child){
      margin-top: 8px;
      @include media(lg){
        margin-top: 16px;
      }
    }
    a {
      background: $white-color;
      border: 1px solid #7AB6C0;
      color: $base-color;
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      padding: 9px 12px;
      transition: color $transition;
      &:hover {
        color: #7AB6C0;
      }
      i {
        margin-right: 12px;
      }
    }
  }
}



// footer-open-time
//
.footer-open-time {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  @include media(lg){
    margin-top: 0;
  }

  .box-item {
    background: $white-color;
    width: 50%;
    &.is-full {
      width: 100%;
    }
  }

  .title {
    background: #7AB6C0;
    color: $white-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    height: 40px;
    i {
      margin-right: 10px;
      font-size: 1.6rem;
    }
  }

  .box-text {
    font-size: 1.6rem;
    padding: 16px 10px;
    text-align: center;

    @include media(lg){
      padding: 16px 20px;
    }


    ul {
      > li {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 5px;

        @include media(lg){
          justify-content: center;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      .text-heading {
        flex: 0 0 auto;
        padding-right: 5px;
        color: $base-color;
      }

      .text-time {
        flex: 1;
        @include media(lg){
          flex: 0 0 auto;
        }
      }
    }
  }

  .box-item:first-child {
    .title {
      border-right: 1px solid #fff;
    }

    .box-text {
      border-right: 1px solid $base-color;
    }
  }
}
