@charset 'UTF-8';

// wrapper
.burger_wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  @include media(lg){
    display: none;
  }
  i {
    color: #999;
    display: inline-block;
    font-size: 2.4rem;
    margin-right: 6px;
  }
}



// Burger
// ------
.burger_icon {
  width: map-get( $burger-border-width, 'sm' );
  span {
    background: $burger-border-color;
    display: block;
    width: 100%;
    height: $burger-border-thickness; // 太さ
    transition: transform $transition, opacity $transition;
    &:nth-of-type(2) {
      margin: map-get( $burger-border-margin, 'sm' ) 0; // ボーダー同士の間隔
    }
  }
}



