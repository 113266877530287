@charset 'UTF-8';

// footer
// ------

// .footer-container の横幅 .container と合わせたい場合は $container-width を使用する
$footer-container-width: (
  'sm': map-get( $container-width, sm ),
  'lg': map-get( $container-width, lg )
) !default;

// .footer-container の左右の padding .container と合わせたい場合は $container-padding を使用する
$footer-container-padding: (
  'sm': map-get( $container-padding, sm ),
  'lg': map-get( $container-padding, lg )
) !default;

// <main> と <footer> の間隔
$footer-padding-top: (
  sm: 30px,
  lg: 58px
) !default;

$background-footer: #f9f6f2 !default; // footer-wrap の background-color
