@charset 'UTF-8';

// 初期設定
// ========== ========== ========== ========== ========== ==========



// Font family
//
$font-family: 'YakuHanJPs', -apple-system  BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, arial, sans-serif;



// body の text color
//
$body-text-color: #333 !default;



// body の background-color
//
$background-body: #fff !default;



// body の文字サイズ（指定：px）
//
$base-font-size: (
  sm: 13,
  lg: 16
) !default;



// body の letter-spacing（単位は自由）
//
$body-letter-spacing: (
  sm: 0,
  lg: 0
) !default;



// body の line-height
//
$base-line-height: (
  sm: 1.4,
  lg: 1.6
) !default;



// anchor
//

// リンクのテキストカラー
//
$link-color: #333 !default;



// アンダーライン
//
$link-decoration: none !default;



// ホバー時のテキストカラー
//
$link-hover-color: darken( $link-color, 15% ) !default;



// ホバー時のアンダーライン
//
$link-hover-decoration: none !default;



// horizontal line
//

// ボーダーの種類
//
$hr-border: 1px solid #e5e5e5 !default;



// ボーダー上下の間隔
//
$hr-margin: (
  'sm' : ( 'top' : 1em, 'bottom' : 1em ),
  'lg' : ( 'top' : 1em, 'bottom' : 1em )
) !default;
