@charset 'UTF-8';

// Label
// -----
.label {
  background: multi-array( $label-color, 'base', 'bg' );
  border: $label-border solid transparent;
  border-radius: $label-border-radius;
  color: multi-array( $label-color, 'base', 'color' );
  display: inline-block;
  font-size: $label-font-size;
  line-height: 1.42875 !important;
  padding: map-unit( $label-padding-tb, sm ) map-unit( $label-padding-lr, sm );
  text-align: center;
  position: relative;
  @include media(lg) {
    padding: map-unit( $label-padding-tb, lg ) map-unit( $label-padding-lr, lg );
  }
  // bacground color pattern
  @each $name, $value in $label-color {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      color: map-get( $value, 'color' );
    }
  }
  // Color Pattern gray & common color
  @each $name, $value in $basic-colors {
    &.#{$name} {
      background: $value;
      color: inherit;
    }
  }

  &.outline {
    background: multi-array( $label-outline-color, 'base', 'bg' );
    border: $label-border solid multi-array( $label-outline-color, 'base', 'border' );
    color: multi-array( $label-outline-color, 'base', 'color' );

    // border color pattern
    @each $name, $value in $label-outline-color {
      &.#{$name} {
        border: $label-border solid map-get( $value, 'border' );
        color: map-get( $value, 'color' );
      }
    }
    // Color Pattern gray & common color
  @each $name, $value in $basic-colors {
    &.#{$name} {
      border: $label-border solid $value;
      color: $value;
    }
  }
  }

  &.expanded {
    display: block;
  }
}

span.label {
  margin-left: .5em;
  margin-right: 0;
  &.left {
    margin-left: 0;
    margin-right: .5em;
  }
}
