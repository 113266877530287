@charset 'UTF-8';

// typography
// ----------

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: .4em;
  line-height: 1.4;
}

h1 {
  font-size: $h1-font-size;
  &.first {
    font-size: 2.4rem;
    font-weight: normal;
    color: #707070;
    line-height: 1.3;
    padding-left: 7px;
    border-left: 4px solid $base-color;
    margin-bottom: 30px;
    @include media(lg){
      font-size: 3.6rem;
      line-height: 1.1;
      padding-left: 20px;
      border-left: 8px solid $base-color;
      margin-bottom: 60px;
    }

    &.sm {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.3;
      padding-left: 10px;
      border-left: 2px solid $base-color;
      @include media(lg){
        font-size: 2rem;
        padding-left: 28px;
        border-left: 2px solid $base-color;
        margin-bottom: 15px;
      }
    }
  }
  &.second {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-size: 2.4rem;
    font-weight: normal;
    color: #707070;

    @include media(lg){
      margin-bottom: 60px;
      font-size: 4rem;
    }

    i {
      margin-right: 5px;
      font-size: 2rem;

      @include media(lg){
        margin-right: 25px;
        font-size: 4.8rem;
      }

      &-pink {
        color: #F2BBBB;
      }
    }
  }
}

h2 {
  font-size: $h2-font-size;
  &.first {
    font-size: 2.4rem;
    font-weight: normal;
    color: $gray-500;
    text-align: center;
    margin-bottom: 15px;
    @include media(lg){
      font-size: 2.8rem;
      margin-bottom: 35px;
    }

    span {
      display: block;
      font-size: 1rem;
      color: $base-color;
      margin-top: 0;
      @include media(lg){
        font-size: 1.2rem;
        margin-top: 10px;
      }
    }
  }
  &.second {
    margin-bottom: 15px;
    padding: 9px 0 9px 8px;
    border-left: 4px solid #55A4B2;
    border-top: 1px dashed #ccc;
    font-weight: bold;
    font-size: 2rem;
    color: #707070;
    line-height: 1.4;
    margin-bottom: 55px;

    @include media(lg){
      margin-bottom: 60px;
      padding: 15px 0 15px 25px;
      font-size: 2.4rem;
    }
  }
}

h3 {
  font-size: $h3-font-size;
  &.first {
    color: #707070;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.3;
    padding-left: 10px;
    border-left: 2px solid $base-color;
    margin-bottom: 1em;
    @include media(lg){
      font-size: 2rem;
      line-height: 1.1;
      padding-left: 28px;
      border-left: 2px solid $base-color;
      margin-bottom: 1em;
    }
  }
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  color: $first-color;
  font-size: $h6-font-size;
  margin-bottom: .875em;
}


p {
  font-size: inherit;
  letter-spacing: map-unit( $paragraph-letter-spacing, sm );
  line-height: map-unit( $paragraph-line-height, sm );
  text-rendering: optimizeLegibility;
  @include media(lg) {
    letter-spacing: map-unit( $paragraph-letter-spacing, lg );
    line-height: map-unit( $paragraph-line-height, lg );
  }
}

// ※
.asterisk {
  font-family: -apple-system  BlinkMacSystemFont, 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif;
}

// 主に paragraph を段落として使用しない場合に margin-bottom を打ち消す
.phrase {
  margin-bottom: 0 !important;
}

// イタリック
// -------
i {
  font-style: italic;
  line-height: inherit;
}

// ボールド
// ------
strong,
em,
b {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

// code
// ----
pre {
  background: #f2f2f2;
  margin: 1em 0;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code {
  background: transparent;
  color: #545454;
  font-family: 'Source Code Pro', monospace;
  font-size: .9375em;
  font-weight: bold;
  vertical-align: initial;
}
